import { useImperativeHandle } from "react";
import { EMPTY_FIELD } from "../constants";
import { getColorHexByName } from "../utils";
import {
  CategorySentimentResponseItem,
  CategoryTimeEvolutionResponseItem,
  SentimentsByMetadataResponseItem,
  SentimentDistributionResponseItem,
  MetricResponseItem,
  MetricTimeEvolutionResponseItem,
  MetricByCategoryResponseItem,
  NPSTimeItem,
  CategoryByMetadataResponse,
  MetadataFrequencyResponseItem,
  MetricTimeDataByMetadataResponse,
  CountOverTimeResponseItem,
} from "./model.interfaces";
import { CapacityState, CapacityStateResponse } from "./settings";
import { IntlShape, useIntl } from "react-intl";

export const formatCategorySentimentResponseData = (
  data: CategorySentimentResponseItem[]
) => {
  const formattedData: SentimentDataPoint[] = [];
  data.forEach((elem: CategorySentimentResponseItem) => {
    if (elem.category_name === null) {
      return;
    }
    const n_pos = elem.n_positive_topic_segments ?? 0;
    const n_neg = elem.n_negative_topic_segments ?? 0;
    const n_neu = elem.n_neutral_topic_segments ?? 0;
    const tot = n_neg + n_pos + n_neu;
    const index = (100 * (n_pos - n_neg)) / tot;
    formattedData.push({
      n_negative: n_neg,
      n_positive: n_pos,
      n_neutral: n_neu,
      label: elem.category_name,
      n_total: tot,
      index: index,
    });
  });
  return formattedData;
};

export type AbsolutCountDataPoint = {
  count: number;
  identifier: string;
};

export type CountDataPoint = {
  count: number;
  label: string;
  baseCount?: number;
};

export type LabeledDataPoint = {
  identifier: string;
  color: string;
  detail2?: string;
};

export type SentimentDataPoint = {
  n_negative: number;
  n_neutral: number;
  n_positive: number;
  label: string;
  n_total: number;
  index: number;
};

export type LabeledCountDataPoint = LabeledDataPoint & CountDataPoint;

export type TimeEvolutionDataPoint = {
  date: Date;
  value: number;
  total: number;
} & CountDataPoint;

export type MetricTimeEvolutionDataPoint = {
  date: Date;
  value: number;
  promoters: number;
  passives: number;
  detractors: number;
  total: number;
  color: string;
};

export type MetricPerCategoryDataPoint = {
  label: string;
  n_positive: number;
  n_negative: number;
  n_neutral: number;
  n_total: number;
  color: string;
  value: number;
  index: number;
  identifier: string;
};

export type LabeledSentimentDataPoint = LabeledDataPoint & SentimentDataPoint;

export type LabeledTimeEvolutionDataPoint = LabeledDataPoint &
  TimeEvolutionDataPoint;

export const formatCategoryTimeEvolutionResponseData = (
  data: CategoryTimeEvolutionResponseItem[]
) => {
  const tmp: { [key: string]: TimeEvolutionDataPoint } = {};
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    // if (d.date === null) {
    //   continue;
    // }
    Object.keys(d).forEach((key) => {
      if (key !== "date" && key !== "total" && d[key] !== null) {
        const tmpDate = new Date(d.date);
        const offset = tmpDate.getTimezoneOffset() * 60 * 1000;
        const date = new Date(tmpDate.getTime() + offset);
        tmp[`${key}-${d.date}`] = {
          date: date,
          value: (d[key] ?? 0) / Math.max(1, d.total ?? 1),
          count: d[key] ?? 0,
          label: key,
          total: d.total ?? 0,
        };
      }
    });
  }
  return Object.values(tmp);
};

function getNPSCategory(key: string, maxKey?: number) {
  const fkey = key.split(".")[0];
  if (fkey === "9" || fkey === "10") {
    return "promoters";
  } else if (fkey === "7" || fkey === "8") {
    return "passives";
  } else if (fkey !== "null") {
    return "detractors";
  }
}

function getCSATCategory(key: string, maxKey?: number) {
  const { limitSatisfied, limitNeutral } = getCSATLimits(maxKey ?? 5);
  const fkey = parseInt(key.split(".")[0]);
  if (fkey > limitSatisfied) {
    return "promoters";
  } else if (fkey > limitNeutral) {
    return "passives";
  } else if (!Number.isNaN(fkey)) {
    return "detractors";
  }
}

function selectCategoriser(metric: string, maxCSAT?: number) {
  switch (metric) {
    case "nps":
      return getNPSCategory;
    case "csat":
      return getCSATCategory;
    case "ces":
      return getCSATCategory;
    default:
      return (key: string) => key;
  }
}

export const formatMetricsResponseData = (
  data: MetricResponseItem,
  metric: string,
  intl: IntlShape,
  maxCSAT?: number,
  maxCES?: number,
) => {
  var promoters = 0;
  var passives = 0;
  var detractors = 0;
  const categoriser = selectCategoriser(metric);
  const maxKey =
    metric === "csat" ? maxCSAT : metric === "ces" ? maxCES : undefined;
  for (const [key, value] of Object.entries(data)) {
    if (data.hasOwnProperty(key)) {
      const cat = categoriser(key, maxKey);
      switch (cat) {
        case "promoters":
          promoters += value;
          break;
        case "passives":
          passives += value;
          break;
        case "detractors":
          detractors += value;
          break;
        default:
          break;
      }
    }
  }
  return [
    {
      identifier: "positive",
      label:
        metric === "nps"
          ? intl.formatMessage({id: "metric.nps.positive"})
          : metric === "csat"
          ? intl.formatMessage({id: "metric.csat.positive"})
          : intl.formatMessage({id: "metric.ces.positive"}),
      //   value: promoters,
      count: promoters,
      color: getColorHexByName("positive"),
      type: "circle",
    },
    {
      identifier: "neutral",
      label:
        metric === "nps"
          ? intl.formatMessage({id: "metric.nps.neutral"})
          : metric === "csat"
          ? intl.formatMessage({id: "metric.csat.neutral"})
          : intl.formatMessage({id: "metric.ces.neutral"}),
      //   value: passives,
      count: passives,
      color: getColorHexByName("neutral"),
      type: "circle",
    },
    {
      identifier: "negative",
      label:
        metric === "nps"
          ? intl.formatMessage({id: "metric.nps.negative"})
          : metric === "csat"
          ? intl.formatMessage({id: "metric.csat.negative"})
          : intl.formatMessage({id: "metric.ces.negative"}),
      //   value: detractors,
      count: detractors,
      color: getColorHexByName("negative"),
      type: "circle",
    },
  ] as (LabeledCountDataPoint & { type: string })[];
};

const getCSATLimits = (maxValue: number) => {
  switch (maxValue) {
    case 3:
      return {
        limitSatisfied: 2,
        limitNeutral: 1,
      };

    case 5:
      return {
        limitSatisfied: 3,
        limitNeutral: 2,
      };

    case 7:
      return {
        limitSatisfied: 5,
        limitNeutral: 3,
      };

    case 10:
      return {
        limitSatisfied: 8,
        limitNeutral: 6,
      };

    default:
      return {
        limitSatisfied: 3,
        limitNeutral: 2,
      };
  }
};

export const formatCSATMetricsResponseData = (data: {
  [index: string]: number;
}) => {
  var nSatisfied = 0;
  var nNeutral = 0;
  var nNegative = 0;
  const largestKey = Object.keys(data).reduce((acc, item) => {
    const itemKey = parseInt(item.split(".")[0]);
    if (itemKey > acc) {
      return itemKey;
    }
    return acc;
  }, 0);

  const { limitSatisfied, limitNeutral } = getCSATLimits(largestKey);

  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      var val = data[key];
      const fkey = key.split(".")[0];
      if (fkey === "null") {
        continue;
      }
      const keyValue = parseInt(fkey);
      if (keyValue > limitSatisfied) {
        nSatisfied += val;
      }
      if (keyValue <= limitSatisfied && keyValue > limitNeutral) {
        nNeutral += val;
      }
      if (keyValue <= limitNeutral) {
        nNegative += val;
      }
    }
  }
  return [
    {
      identifier: "positive",
      label: "Satisfied",
      //   value: nSatisfied,
      count: nSatisfied,
      color: getColorHexByName("positive"),
      type: "circle",
    },
    {
      identifier: "neutral",
      label: "Neutral",
      //   value: nNeutral,
      count: nNeutral,
      color: getColorHexByName("neutral"),
      type: "circle",
    },
    {
      identifier: "negative",
      label: "Unsatisfied",
      //   value: nNegative,
      count: nNegative,
      color: getColorHexByName("negative"),
      type: "circle",
    },
  ] as (LabeledCountDataPoint & { type: string })[];
};

export const formatSentimentsByMetadataResponseData = (
  data: SentimentsByMetadataResponseItem[]
) => {
  const formattedData: (SentimentDataPoint & { count: number })[] = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const formattedItem = {
      n_negative: item.negative ?? 0,
      n_neutral: item.neutral ?? 0,
      n_positive: item.positive ?? 0,
      n_total:
        (item.positive ?? 0) + (item.neutral ?? 0) + (item.negative ?? 0),
      label: item.name,
      count: (item.positive ?? 0) + (item.neutral ?? 0) + (item.negative ?? 0),
      index:
        ((item.positive ?? 0) - (item.negative ?? 0)) /
        ((item.positive ?? 0) + (item.negative ?? 0) + (item.neutral ?? 0)),
    };
    formattedData.push(formattedItem);
  }
  const sortedFormattedData = formattedData.sort((a, b) => {
    return a.count > b.count ? 1 : -1;
  });
  return sortedFormattedData;
};

export const formatMetricTimeEvolutionResponseData = (
  data: MetricTimeEvolutionResponseItem[],
  metric: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  switch (metric) {
    case "nps":
      return formatNPSTimeEvolutionResponseData(data as NPSTimeItem[]);
    case "csat":
      return formatCSATTimeEvolutionResponseData(data, maxCSAT);
    case "ces":
      return formatCSATTimeEvolutionResponseData(data, maxCES);
    default:
      return [];
  }
};

export const formatNPSTimeEvolutionResponseData = (data: NPSTimeItem[]) => {
  const tmp: { [key: string]: MetricTimeEvolutionDataPoint } = {};
  for (let i = 0; i < data.length; i++) {
    const datum = data[i];
    const promoters = (datum["9.0"] ?? 0) + (datum["10.0"] ?? 0);
    const passives = (datum["7.0"] ?? 0) + (datum["8.0"] ?? 0);
    const detractors =
      (datum["0.0"] ?? 0) +
      (datum["1.0"] ?? 0) +
      (datum["2.0"] ?? 0) +
      (datum["3.0"] ?? 0) +
      (datum["4.0"] ?? 0) +
      (datum["5.0"] ?? 0) +
      (datum["6.0"] ?? 0);
    const tot = promoters + passives + detractors;
    const index = (100 * (promoters - detractors)) / (tot + 0.001);
    const tmpDate = new Date(datum.date);
    const offset = tmpDate.getTimezoneOffset() * 60 * 1000;
    const date = new Date(tmpDate.getTime() + offset);
    tmp[datum.date] = {
      date: date,
      promoters: promoters,
      passives: passives,
      detractors: detractors,
      total: tot,
      value: index,
      color: "black",
    };
  }
  const values = Object.values(tmp);
  return values.filter((item) => item.total > 0);
};

export const formatCSATTimeEvolutionResponseData = (
  data: MetricTimeEvolutionResponseItem[],
  maxCSAT?: number
) => {
  const { limitSatisfied, limitNeutral } = getCSATLimits(maxCSAT ?? 5);
  const tmp: { [key: string]: MetricTimeEvolutionDataPoint } = {};
  for (let i = 0; i < data.length; i++) {
    const datum = data[i];
    for (var key in datum) {
      if (datum.hasOwnProperty(key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        var val = datum[key];
        const fkey = key.split(".")[0];
        if (fkey === "null") {
          continue;
        }
        const keyValue = parseInt(fkey);
        const tmpDate = new Date(datum.date);
        const offset = tmpDate.getTimezoneOffset() * 60 * 1000;
        const date = new Date(tmpDate.getTime() + offset);
        if (keyValue > limitSatisfied) {
          if (tmp[datum.date]) {
            tmp[datum.date].promoters += val;
            tmp[datum.date].total += val;
            tmp[datum.date].value =
              tmp[datum.date].promoters / (tmp[datum.date].total + 0.001);
          } else {
            tmp[datum.date] = {
              date: date,
              promoters: val,
              passives: 0,
              detractors: 0,
              total: val,
              value: 1,
              color: "black",
            };
          }
        }
        if (keyValue <= limitSatisfied && keyValue > limitNeutral) {
          if (tmp[datum.date]) {
            tmp[datum.date].passives += val;
            tmp[datum.date].total += val;
            tmp[datum.date].value =
              tmp[datum.date].promoters / (tmp[datum.date].total + 0.001);
          } else {
            tmp[datum.date] = {
              date: date,
              promoters: 0,
              passives: val,
              detractors: 0,
              total: val,
              value: 0,
              color: "black",
            };
          }
        }
        if (keyValue <= limitNeutral) {
          if (tmp[datum.date]) {
            tmp[datum.date].detractors += val;
            tmp[datum.date].total += val;
            tmp[datum.date].value =
              tmp[datum.date].promoters / (tmp[datum.date].total + 0.001);
          } else {
            tmp[datum.date] = {
              date: date,
              promoters: 0,
              passives: 0,
              detractors: val,
              total: val,
              value: 0,
              color: "black",
            };
          }
        }
      }
    }
  }
  const values = Object.values(tmp);
  return values.filter((item) => item.total > 0);
};

export const formatMetricTimeEvolutionPerMetadataResponseData = (
  data: MetricTimeDataByMetadataResponse,
  metric: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  switch (metric) {
    case "nps":
      return formatNPSTimeEvolutionMetricPerMetadataResponseData(data);
    case "csat":
      return formatCSATTimeEvolutionMetricPerMetadataResponseData(
        data,
        maxCSAT
      );
    case "ces":
      return formatCSATTimeEvolutionMetricPerMetadataResponseData(data, maxCES);
  }
};

export const formatMetricPerCategoryResponseData = (
  data: MetricByCategoryResponseItem,
  metric: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  switch (metric) {
    case "nps":
      return formatNPSMetricPerCategoryResponseData(data);
    case "csat":
      return formatCSATMetricPerCategoryResponseData(data, maxCSAT);
    case "ces":
      return formatCSATMetricPerCategoryResponseData(data, maxCES);
    default:
      return [];
  }
};

export const formatCSATTimeEvolutionMetricPerMetadataResponseData = (
  data: MetricTimeDataByMetadataResponse,
  maxCSAT?: number
) => {
  const tmp: { [key: string]: MetricTimeEvolutionDataPoint[] } = {};
  for (const [key, values] of Object.entries(data)) {
    const fmtValues = Object.entries(values).map(([date, value]) => {
      return {
        date: date,
        ...value,
      };
    });
    tmp[key] = formatCSATTimeEvolutionResponseData(fmtValues, maxCSAT);
  }
  return tmp;
};

export const formatNPSTimeEvolutionMetricPerMetadataResponseData = (
  data: MetricTimeDataByMetadataResponse
) => {
  const tmp: { [key: string]: MetricTimeEvolutionDataPoint[] } = {};
  for (const [key, values] of Object.entries(data)) {
    const fmtValues = Object.entries(values).map(([date, value]) => {
      return {
        date: date,
        ...value,
      };
    });
    tmp[key] = formatNPSTimeEvolutionResponseData(fmtValues);
  }
  return tmp;
};

export const formatNPSMetricPerCategoryResponseData = (
  data: MetricByCategoryResponseItem,
  maxCSAT?: number
) => {
  const tmp: { [key: string]: MetricPerCategoryDataPoint } = {};
  for (const [key, value] of Object.entries(data)) {
    const datum = value.value_counts;
    const promoters = (datum["9.0"] ?? 0) + (datum["10.0"] ?? 0);
    const passives = (datum["7.0"] ?? 0) + (datum["8.0"] ?? 0);
    const detractors =
      (datum["0.0"] ?? 0) +
      (datum["1.0"] ?? 0) +
      (datum["2.0"] ?? 0) +
      (datum["3.0"] ?? 0) +
      (datum["4.0"] ?? 0) +
      (datum["5.0"] ?? 0) +
      (datum["6.0"] ?? 0);
    const tot = promoters + passives + detractors;
    const index = (100 * (promoters - detractors)) / tot;
    tmp[key] = {
      label: key,
      n_positive: promoters ?? 0,
      n_neutral: passives ?? 0,
      n_negative: detractors ?? 0,
      n_total: tot ?? 0,
      value: index ?? 0,
      index: index ?? 0,
      color: "black",
      identifier: "index",
    };
  }
  return Object.values(tmp);
};

export const formatCSATMetricPerCategoryResponseData = (
  data: MetricByCategoryResponseItem,
  maxCSAT?: number
) => {
  const { limitSatisfied, limitNeutral } = getCSATLimits(maxCSAT ?? 5);
  const tmp: { [key: string]: MetricPerCategoryDataPoint } = {};
  for (const [cat, value] of Object.entries(data)) {
    const datum = value.value_counts;
    let promoters = 0;
    let passives = 0;
    let detractors = 0;
    let tot = 0;
    for (var key in datum) {
      if (datum.hasOwnProperty(key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        var val = datum[key];
        const fkey = key.split(".")[0];
        if (fkey === "null") {
          continue;
        }
        const keyValue = parseInt(fkey);
        if (keyValue > limitSatisfied) {
          promoters += val;
          tot += val;
        }
        if (keyValue <= limitSatisfied && keyValue > limitNeutral) {
          passives += val;
          tot += val;
        }
        if (keyValue <= limitNeutral) {
          detractors += val;
          tot += val;
        }
      }
    }
    const index = (100 * promoters) / tot;
    tmp[cat] = {
      label: cat,
      n_positive: promoters ?? 0,
      n_neutral: passives ?? 0,
      n_negative: detractors ?? 0,
      n_total: tot ?? 0,
      value: index ?? 0,
      index: index ?? 0,
      color: "black",
      identifier: "index",
    };
  }
  return Object.values(tmp);
};

export const formatSentimentDistributionResponseData = (
  data: SentimentDistributionResponseItem
) => {
  return {
    n_negative: data.n_negative_sentiments,
    n_neutral: data.n_neutral_sentiments,
    n_positive: data.n_positive_sentiments,
  };
};

function getIsLifetimeSubscription(data: CapacityStateResponse) {
  if (data.subscription_details.is_lifetime_subscription === "true") {
    return true;
  } else {
    return false;
  }
}

export const formatCapacityStateResponse = (data: CapacityStateResponse) => {
  return {
    display_name: data.subscription_details.display_name,
    is_lifetime_subscription: getIsLifetimeSubscription(data),
    name: data.subscription_details.name,
    n_analyses: data.subscription_details.n_analyses,
    n_data_rows_subscription: data.subscription_details.n_data_rows,
    n_deepers: data.subscription_details.n_deepers,
    n_metadata_cols: data.subscription_details.n_metadata_cols,
    price: data.subscription_details.price,
    renewal_date: data.subscription_details.renewal_date,
    status: data.subscription_details.status,
    enabled_pipelines: data.subscription_details.enabled_pipelines ?? [],
    purchased: data.data.capacity.purchased,
    reserved: data.data.capacity.reserved,
    used: data.data.capacity.used,
    summary: data.subscription_details.summary,
    available:
      data.data.capacity.purchased.total -
      data.data.capacity.used -
      data.data.capacity.reserved,
  } as CapacityState;
};

export const formatCategoryByMetadataResponseData = (
  data: CategoryByMetadataResponse
) => {
  if (!data.result) {
    return [];
  }
  return data.result.map((item) => {
    return {
      label: item.md_val ?? EMPTY_FIELD,
      count: item.category_count,
      baseCount: item.base_count,
    };
  });
};

export const formatSentimentFrequencyData = (
  data: MetadataFrequencyResponseItem[]
) => {
  return data.map((item) => {
    return {
      label: item.metadata_value ?? EMPTY_FIELD,
      n_total: item.ant_count,
      count: item.ant_count,
    };
  });
};
