import moment from "moment";
import * as d3 from "d3";
import {
  FormattedResponseDataItem,
  FrequencyDataItem,
  LabeledTimeEvolutionDataPoint,
  SentimentDataPoint,
  TimeEvolutionDataPoint,
} from "../dal";
import { DateRange, SelectedFilters } from "../reducers/model_results";
import { SentimentDistributionType } from "../dal/sentiment";
import { AnalysisBatch } from "../reducers/project";
import html2canvas from "html2canvas";
import { RefObject } from "react";
import { jsPDF } from "jspdf";

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const formatDate = (date: string) => {
  return moment(date).format("DD MMM YYYY");
};

const formatDateWithHour = (date: string) => {
  return moment(date).format("DD/MM/YYYY HH:MM:SS");
};

const formatPct = (val: number, digits: number = 1) => {
  const valNew = Math.round(Math.pow(10, digits) * val) / Math.pow(10, digits);
  return `${valNew.toLocaleString()} %`;
};

const formatNumber = (val: number, digits: number = 1) => {
  const valNew = Math.round(Math.pow(10, digits) * val) / Math.pow(10, digits);
  return `${valNew.toLocaleString()}`;
};

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const openInSameTab = (url: string) => {
  window.open(url, "_self");
};

const sliceString = (lbl: string, nChars: number) => {
  return lbl.length > nChars ? lbl.slice(0, nChars) + "..." : lbl;
};

const removeDisabledFilters = (filterValues: SelectedFilters) => {
  var activeFilterValues: SelectedFilters = {};
  for (const k of Object.keys(filterValues)) {
    const filterValue = filterValues[k];
    if (filterValue instanceof Array) {
      const activeFilterValue = filterValue.filter(
        (elem) => elem.enabled ?? false
      );
      if (activeFilterValue.length > 0) {
        activeFilterValues[k] = activeFilterValue;
      }
    } else {
      if (filterValue.enabled ?? false) {
        activeFilterValues[k] = filterValue;
      }
    }
  }
  return activeFilterValues;
};

export const getActivatedFilters = (
  filterValuesIn: SelectedFilters,
  enabledFilters: string[],
  selectedTopicId: string | undefined = undefined,
  query?: string,
  daterange?: DateRange
) => {
  const filterValues = removeDisabledFilters(filterValuesIn);
  var arr = enabledFilters.map((elem) => {
    return { key: elem, state: filterValues[elem] };
  });
  if (!!selectedTopicId) {
    arr.push({
      key: "topic_uuid",
      state: { value: selectedTopicId, label: selectedTopicId },
    });
  }
  if (enabledFilters.includes("query")) {
    if (!!query) {
      arr.push({
        key: "content",
        state: { value: query, label: query },
      });
    }
  }
  if (enabledFilters.includes("daterange")) {
    if (!!daterange?.min && daterange.active) {
      arr.push({
        key: "created_at_from",
        state: { value: daterange.min, label: daterange.min },
      });
    }
    if (!!daterange?.max && daterange.active) {
      arr.push({
        key: "created_at_to",
        state: { value: daterange.max, label: daterange.max },
      });
    }
  }
  if (enabledFilters.includes("metadata")) {
    for (const mo of Object.keys(filterValues)) {
      if (mo.startsWith("metadata")) {
        arr.push({ key: mo, state: filterValues[mo] });
      }
    }
  }

  const activatedFilters = arr.filter((elem) => {
    return elem.state !== undefined && elem.state !== null;
  });
  return activatedFilters;
};

export const buildQueryParamsBackend = (
  filterValuesIn: SelectedFilters,
  daterange?: DateRange
) => {
  let combined: { [key: string]: any };
  if (!!daterange) {
    combined = { ...filterValuesIn, ...(daterange ?? {}) };
  } else {
    combined = { ...filterValuesIn };
  }

  const simplified: { [key: string]: any } = {};

  for (const key in combined) {
    const value = combined[key];

    if (key === "active") {
    } else if (key === "min") {
      simplified[`created_at_from`] = value;
    } else if (key === "max") {
      simplified[`created_at_to`] = value;
    } else if (Array.isArray(value)) {
      // If value is an array, simplify the array by extracting 'value' from enabled objects
      simplified[key] = value
        .filter((item) => item.enabled)
        .map((item) => item.value);
    } else if (typeof value === "object" && value !== null) {
      // If value is an object, check if 'enabled' is true and take the 'value'
      if (value.enabled) {
        simplified[key] = value.value;
      }
    } else {
      // For primitive values, copy them directly
      simplified[key] = value;
    }
  }
  return simplified;
};

export const buildQueryParamsNew = (
  filterValuesIn: SelectedFilters,
  enabledFilters: string[],
  selectedTopicId: string | undefined = undefined,
  query?: string,
  daterange?: DateRange
) => {
  const activatedFilters = getActivatedFilters(
    filterValuesIn,
    enabledFilters,
    selectedTopicId,
    query,
    daterange
  );

  if (activatedFilters.length == 0) {
    return "?";
  }
  const queryParamBlocks = activatedFilters.map((elem) => {
    return buildQPBlock(elem);
  });
  return "?" + queryParamBlocks.join("&") + "&";
};

const buildQPBlock = (elem: any) => {
  if (elem.state instanceof Array) {
    const blocks = elem.state.map((s: any) => {
      const key = encodeURIComponent(elem.key);
      const val = encodeURIComponent(s.value);
      const part = `${key}=${val}`;
      return part;
    });
    return blocks.join("&");
  } else {
    return `${encodeURIComponent(elem.key)}=${encodeURIComponent(
      elem.state.value
    )}`;
  }
};

const buildMetadataFilterQueryString = (selectedMetadata: {
  [key: string]: string | { value: string }[];
}) => {
  var queryString = ``;
  for (const k of Object.keys(selectedMetadata)) {
    const val = selectedMetadata[k];
    if (!!val) {
      if (Array.isArray(val)) {
        const cats = val.map((cat) => {
          return `${k}=${cat.value}&`;
        });
        queryString = queryString + cats.join("");
      } else {
        queryString = queryString + `${k}=${val}&`;
      }
    }
  }
  return queryString;
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport: any) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const getBadgeColor = (status: string) => {
  switch (status) {
    case "uploaded":
      return "success";
    case "loading":
      return "secondary";
    case "pending":
      return "secondary";
    case "error":
      return "danger";
    case "training.error":
      return "danger";
    case "training.denied":
      return "danger";
    case "validation.error":
      return "danger";
    case "initialized":
      return "warning";
    case "training":
      return "warning";
    case "validated":
      return "secondary";
    case "validating":
      return "secondary";
    case "success":
      return "success";
    case "confirmation.needed":
      return "warning";
    default:
      return "warning";
  }
};

const isNotUndefined = (item: any | undefined): item is any => {
  return !!item;
};

const getInputFieldClassName = (is_touched: boolean, error: string) => {
  if (!is_touched) {
    return "";
  }
  if (!error) {
    return "is-valid";
  }
  return "is-invalid";
};

function calcIndex(
  n_positive: number,
  n_negative: number,
  n_neutral: number,
  totalIn?: number
) {
  const total = totalIn ?? n_positive + n_negative + n_neutral;
  if (total === 0) {
    return 0;
  }
  const index = (n_positive - n_negative) / total;
  return Math.round(index * 1000) / 10;
}

function calcCSATPotential(
  disBase: SentimentDistributionType,
  disDetail: SentimentDistributionType
) {
  const baseCSAT = calcCSAT(
    disBase.n_positive,
    disBase.n_negative,
    disBase.n_neutral
  );
  const promotersNew =
    disBase.n_positive + disDetail.n_negative + disDetail.n_neutral;
  const detractorsNew = disBase.n_negative - disDetail.n_negative;
  const pssivesNew = disBase.n_neutral - disDetail.n_neutral;
  const newCSAT = calcCSAT(promotersNew, detractorsNew, pssivesNew);
  const res = newCSAT - baseCSAT;
  return res;
}

function calcCSATContribution(
  disBase: SentimentDistributionType,
  disDetail: SentimentDistributionType
) {
  const totalBase = disBase.n_positive + disBase.n_negative + disBase.n_neutral;
  return calcCSAT(
    disDetail.n_positive,
    disDetail.n_negative,
    disDetail.n_neutral,
    totalBase
  );
}

function calcIndexPotential(
  disBase: SentimentDistributionType,
  disDetail: SentimentDistributionType
) {
  const baseNPS = calcIndex(
    disBase.n_positive,
    disBase.n_negative,
    disBase.n_neutral
  );
  const promotersNew =
    disBase.n_positive + disDetail.n_negative + disDetail.n_neutral;
  const detractorsNew = disBase.n_negative - disDetail.n_negative;
  const pssivesNew = disBase.n_neutral - disDetail.n_neutral;
  const newNPS = calcIndex(promotersNew, detractorsNew, pssivesNew);
  const res = newNPS - baseNPS;
  return res;
}

function calcIndexContribution(
  disBase: SentimentDistributionType,
  disDetail: SentimentDistributionType
) {
  const totalBase = disBase.n_positive + disBase.n_negative + disBase.n_neutral;
  return calcIndex(
    disDetail.n_positive,
    disDetail.n_negative,
    disDetail.n_neutral,
    totalBase
  );
}

const calcCSAT = (
  promoters: number,
  detractors: number,
  passives: number,
  totalIn?: number
) => {
  const total = totalIn ?? promoters + detractors + passives;
  if (total === 0) {
    return 0;
  }
  const nps = Math.round(1000 * (promoters / total)) / 10;
  return nps;
};

const getIdentifierMap = (data: { n_total: number; label: string }[]) => {
  const preSortedItems = data.sort((a, b) => {
    const d = b.n_total - a.n_total;
    if (d === 0) {
      return -1;
    }
    return d;
  });
  let identifierMap: { [key: string]: string } = {};
  for (let i = 0; i < preSortedItems.length; i++) {
    identifierMap[preSortedItems[i].label] = String.fromCharCode(65 + i);
  }
  return identifierMap;
};

const getColorMap = (data: { n_total: number; label: string }[]) => {
  const preSortedItems = data.sort((a, b) => {
    const d = b.n_total - a.n_total;
    if (d === 0) {
      return -1;
    }
    return d;
  });
  const colors = d3.scaleOrdinal(d3.schemeCategory10);
  let colorMap: { [key: string]: string } = {};
  for (let i = 0; i < preSortedItems.length; i++) {
    colorMap[preSortedItems[i].label] = colors(preSortedItems[i].label);
  }
  return colorMap;
};

function colorItems<T extends { label: string }>(
  data: T[],
  colorMap: {
    [key: string]: string;
  }
) {
  return data.map((item) => ({
    ...item,
    color: colorMap[item.label],
  }));
}

function identifyItems<T extends { label: string }>(
  data: T[],
  identifierMap: { [key: string]: string }
) {
  return data.map((item) => {
    return {
      ...item,
      identifier: identifierMap[item.label],
    };
  });
}

function prepItems<T extends { label: string }>(
  data: T[],
  colorMap: { [key: string]: string },
  identifierMap: { [key: string]: string }
) {
  const namedItems = identifyItems(data, identifierMap);
  return colorItems(namedItems, colorMap);
}

function getSelectedOccurrence(elem: FormattedResponseDataItem, key: string) {
  switch (key) {
    case "negative":
      return elem.negative;
    case "neutral":
      return elem.neutral;
    case "positive":
      return elem.positive;
    default:
      return elem.total;
  }
}

const getCountValueFromFrequencyItem = (
  x: FrequencyDataItem,
  valueKey: string
) => {
  if (valueKey === "count") {
    return x.count;
  } else if (valueKey === "value") {
    return x.value;
  } else if (valueKey === "total") {
    return x.total ?? x.count;
  } else if (valueKey === "negative") {
    return x.negative;
  } else if (valueKey === "neutral") {
    return x.neutral;
  } else if (valueKey === "positive") {
    return x.positive;
  } else {
    return x.count;
  }
};

const getColorHexByName = (name: string) => {
  switch (name) {
    case "negative":
      return "#e05281";
    case "neutral":
      return "#bdd6db";
    case "positive":
      return "#52e099";
    default:
      return "primary";
  }
};

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function getSetupUrlForBatch(batch: AnalysisBatch) {
  const bid = batch.id;
  const pid = batch.project_id;
  const mid = batch.ml_model;
  const status = batch.status;
  const baseUrl = `/projects/${pid}/analysis/${mid}/batches/${bid}/setup`;
  switch (status) {
    case "validating":
      return baseUrl + "/validation";
    case "pending":
      return baseUrl + "/column_mapping";
    case "validated":
      return baseUrl + "/deepers";
    case "confirmation.needed":
      return baseUrl + "/deepers";
    case "initialized":
      return baseUrl.replace("/setup", "/processing");
    case "validation.error":
      return baseUrl + "/column_mapping";
    case "success":
      return `/projects/${pid}/results/${mid}`;
    default:
      return baseUrl;
  }
}

function getSetupUrlForDeeperBatch(batch: AnalysisBatch) {
  const bid = batch.id;
  const pid = batch.project_id;
  const mid = batch.ml_model;
  const status = batch.status;
  const baseUrl = `/projects/${pid}/deepers/${mid}/batches/${bid}/setup`;
  switch (status) {
    case "validating":
      return baseUrl + "/validation";
    case "pending":
      return baseUrl + "/column_mapping";
    case "validated":
      return baseUrl + "/confirmation";
    case "confirmation.needed":
      return baseUrl + "/confirmation";
    case "initialized":
      return baseUrl.replace("/setup", "/processing");
    case "validation.error":
      return baseUrl + "/column_mapping";
    case "success":
      return `/projects/${batch.project_id}/deepers/${batch.ml_model}/batches/${batch.id}/create`;
    default:
      return baseUrl;
  }
}

const handleDownloadImage = async (
  element: RefObject<HTMLInputElement>,
  setExporting: CallableFunction,
  name?: string
) => {
  const filename = name ? name.toLowerCase().replace(/\s/g, "_") : "export";
  setExporting(true);
  if (element.current === null) {
    setExporting(false);
    return;
  }

  await sleep(1000);

  const canvas = await html2canvas(element.current);

  const data = canvas.toDataURL("image/jpg");
  const link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = data;
    link.download = filename + ".jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(data);
  }
  setExporting(false);
};

const handleDownloadPdf = async (
  element: RefObject<HTMLInputElement>,
  setExporting: CallableFunction,
  orientation: "l" | "p",
  name?: string
) => {
  const filename = name ? name.toLowerCase().replace(/\s/g, "_") : "export";
  setExporting(true);
  if (element.current === null) {
    setExporting(false);
    return;
  }
  await sleep(400);
  const canvas = await html2canvas(element.current);
  const data = canvas.toDataURL("image/png");

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);

  const pdfOut = new jsPDF(orientation, "px", [
    imgProperties.width,
    imgProperties.height,
  ]);
  pdfOut.addImage(data, "PNG", 0, 0, imgProperties.width, imgProperties.height);
  pdfOut.save(filename + ".pdf");
  setExporting(false);
};

const formatDateWithPeriod = (
  dateInput: Date | string | number,
  period: string
): string => {
  if (dateInput === null) {
    return "n/a";
  }
  const date = new Date(dateInput);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const yearFull = date.getFullYear();
  const yearShort = yearFull.toString().slice(-2);

  switch (period) {
    case "day":
    case "week":
      return `${day} ${month} '${yearShort}`;
    case "month":
    case "quartal":
    case "quarter":
      return `${month} '${yearShort}`;
    case "year":
      return `${yearFull}`;
    default:
      return `${day} ${month} '${yearShort}`; // Default to day format if period is unrecognized
  }
};

const offSetDate = (date: string) => {
  const tmpDate = new Date(date);
  const offset = tmpDate.getTimezoneOffset() * 60 * 1000;
  const offSetDate = new Date(tmpDate.getTime() + offset);
  return offSetDate.toDateString();
};

export {
  calcCSAT,
  calcCSATContribution,
  calcCSATPotential,
  calcIndex,
  calcIndexContribution,
  calcIndexPotential,
  colorItems,
  formatBytes,
  formatDate,
  formatDateWithHour,
  formatDateWithPeriod,
  getColorHexByName,
  getColorMap,
  getCountValueFromFrequencyItem,
  getIdentifierMap,
  getSelectedOccurrence,
  getSetupUrlForBatch,
  getSetupUrlForDeeperBatch,
  identifyItems,
  lazyRetry,
  openInNewTab,
  openInSameTab,
  formatPct,
  formatNumber,
  getBadgeColor,
  isNotUndefined,
  getInputFieldClassName,
  prepItems,
  sleep,
  sliceString,
  handleDownloadImage,
  handleDownloadPdf,
  offSetDate,
};
