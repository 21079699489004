import { createSelector } from "reselect";
import { AppState } from "../reducers/interfaces";
import { select } from "d3";
import { v5 as uuidv5 } from "uuid";

type User = {
  token: string;
  person: {
    name: string;
    id: string;
    account: string;
    email: string;
    given_name: string;
    family_name: string;
  };
};

/**
 * Direct selector to the login state domain
 */
const selectAuthDomain = (state: AppState) => state.auth;

export const isAuthenticated = createSelector(selectAuthDomain, (substate) =>
  Boolean(substate.currentUser?.token)
);

export const selectIsClientAccount = createSelector(
  selectAuthDomain,
  (substate) => {
    const encodedClientAccounts = [
      // "e9c8ef1f-06ae-5acb-a3a3-242797deb868",
      "9f26027e-8e50-5744-b286-2d3730449aab",
    ];
    const acc = substate.currentUser?.person.account;
    if (!acc) {
      return false;
    }
    const u5 = uuidv5(acc, acc);
    if (encodedClientAccounts.includes(u5)) {
      return true;
    }
    return false;
  }
);

export const selectAuthData = createSelector(
  selectAuthDomain,
  (substate) => substate.currentUser
);

export const selectIsInviteeAiConsultant = createSelector(
  selectAuthDomain,
  (substate) =>
    Boolean(
      substate.currentUser?.person?.roles?.includes("dt-invitee-ai-consultant")
    ) ?? false
);

export const selectIsProjectManager = createSelector(
  selectAuthDomain,
  (substate) =>
    Boolean(
      substate.currentUser?.person?.roles?.includes("dt-project-manager")
    ) ?? false
);

export const selectIsInvitee = createSelector(
  selectAuthDomain,
  (substate) =>
    Boolean(substate.currentUser?.person?.roles?.includes("dt-invitee")) ??
    false
);

export const selectIsAnyInvitee = createSelector(
  selectIsInviteeAiConsultant,
  selectIsInvitee,
  (light, invitee) => {
    return light || invitee;
  }
);

export const selectIsAdminUser = createSelector(
  selectIsInviteeAiConsultant,
  selectIsInvitee,
  selectIsProjectManager,
  (light, invitee, pm) => {
    return !(light || invitee || pm);
  }
);

export const selectIsMarcoOrDemo = createSelector(
  selectAuthDomain,
  (substate) => {
    return [
      "f5bc56bc-0936-4af2-b872-3a69b4463e45",
      "61abebda-767b-4245-9fb5-cc0d52df07d7",
      "91aeeef3-762a-4aad-b08d-39612dfc6871",
      "31c2a899-8523-4c09-8b08-8bba846303d6",
      "8243760e-be5c-4181-98e4-73eae2d0b5a4",
      "a5e05c05-19f0-4f5d-9766-3d0873179955",
      "ae7801eb-b3dc-491a-8cc1-b62bb79d82e1",
      "66ae6fec-7420-487b-950b-8f52ba035405",
      "89452a6c-df25-4712-b4e8-a6e5274a8168",
      "5adbbf0e-fba4-4197-8ec1-90c547eea167"
    ].includes(substate.currentUser?.person.account ?? "");
  }
);

export type isAuthenticatedT = ReturnType<typeof isAuthenticated>;
