import React, { createContext, useState, useContext, useEffect } from "react";
import { IntlProvider } from "react-intl";

// import locale_en from "@deep-talk/shared/src/translations/en.json";
import locale_es from "@deep-talk/shared/src/translations/es.json";
import locale_en from "@deep-talk/shared/src/translations/en.json"

const data = {
  en: locale_en,
  es: locale_es,
  "es-CL": locale_es,
};

const LocaleContext = createContext();

export function LocaleProvider({ children }) {
  const language = navigator.language.split(/[-_]/)[0];
  const [locale, setLocale] = useState(
    localStorage.getItem("locale") || language
  );
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const msgs = data[locale];
    setMessages(msgs);
    setLocale(locale);
    localStorage.setItem("locale", locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  return useContext(LocaleContext);
}
