import {
  formatCSATMetricsResponseData,
  formatCategoryByMetadataResponseData,
  formatCategorySentimentResponseData,
  formatCategoryTimeEvolutionResponseData,
  formatMetricPerCategoryResponseData,
  formatMetricTimeEvolutionPerMetadataResponseData,
  formatMetricTimeEvolutionResponseData,
  formatMetricsResponseData,
  formatSentimentDistributionResponseData,
  formatSentimentFrequencyData,
  formatSentimentsByMetadataResponseData,
} from "./responseFormatters";
import { API_DISTRIBUTOR, DEEPTALK_API_HOST } from "../constants";
import { ModelResultDetail } from "../reducers/model_results";
import { AnalysisBatch } from "../reducers/project";
import { TopicTimeEvolutionData } from "../reducers/trends";
import { offSetDate } from "../utils";
import api from "../utils/ApiKit";
import {
  ModelStatus,
  ModelTypeAndColumnMapping,
  ModelValueMapping,
  ProjectModelCreatePayload,
  ProjectModelCreateRes,
  ProjectTextModel,
  SaveCategoriesPayload,
  SaveCategoryUserExamplesPayload,
  TopicGroup,
  CategorySentimentResponseItem,
  CategoryTimeEvolutionResponseItem,
  SentimentsByMetadataResponseItem,
  SentimentDistributionResponseItem,
  MetricByCategoryResponseItem,
  MetricTimeEvolutionResponseItem,
  MetricResponseItem,
  MetadataFrequencyResponseItem,
  MetricByMetadataResponse,
  MetricTimeDataByMetadataResponse,
  CountOverTimeResponseItem,
  DistributionResponseItem,
  UserCountOverTimeResponseItem,
  TopicGroupMembership,
} from "./model.interfaces";
import { ProjectFile } from "./projects.interfaces";
import { IntlShape } from "react-intl";

export const editTopicGroupReq = (
  modelId: string,
  id: string,
  name: string
) => {
  return api
    .getInstance()
    .patch(`${DEEPTALK_API_HOST}/models/${modelId}/topic_groups/${id}`, {
      name: name,
    });
};

export const deleteTopicGroupReq = (modelId: string, id: string) => {
  return api
    .getInstance()
    .delete(`${DEEPTALK_API_HOST}/models/${modelId}/topic_groups/${id}`);
};

export const getTopicGroupMembershipsByTopicReq = (
  modelId: number,
  groupId: string
) => {
  return api
    .getInstance()
    .get<TopicGroupMembership[]>(
      `${DEEPTALK_API_HOST}/models/${modelId}/topic_groups_membership/by_topic_group/${groupId}/`
    );
};

export const deleteTopicGroupMembershipsReq = (modelId: string, id: string) => {
  return api
    .getInstance()
    .delete<TopicGroupMembership>(
      `${DEEPTALK_API_HOST}/models/${modelId}/topic_groups_membership/${id}`
    );
};

export const createTopicGroupMembershipsReq = (
  modelId: number,
  topicUUID: string,
  topicGroup: string
) => {
  return api
    .getInstance()
    .post<TopicGroupMembership>(
      `${DEEPTALK_API_HOST}/models/${modelId}/topic_groups_membership/`,
      {
        topic_group: topicGroup,
        topic_uuid: topicUUID,
      }
    );
};

export const getTopicGroupMembershipsReq = (
  modelId: number,
  topicUUID: string
) => {
  return api
    .getInstance()
    .get<TopicGroupMembership[]>(
      `${DEEPTALK_API_HOST}/models/${modelId}/topic_groups_membership/by_topic/${topicUUID}/`
    );
};
export const getTopicGroupsReq = (modelId: number) => {
  return api
    .getInstance()
    .get<TopicGroup[]>(
      `${DEEPTALK_API_HOST}/models/${modelId}/topic_groups/?model_id=${modelId}`
    );
};

export const createTopicGroupReq = (modelId: number, groupName: string) => {
  return api
    .getInstance()
    .post<TopicGroup>(`${DEEPTALK_API_HOST}/models/${modelId}/topic_groups/`, {
      name: groupName,
    });
};

export const getTopicTimeEvolutionReq = (
  modelId: number,
  topic_uuid: string,
  level: number
) => {
  return api
    .getInstance()
    .get<TopicTimeEvolutionData>(
      `${DEEPTALK_API_HOST}/models/${modelId}/trends/?topic_level_${level}=${topic_uuid}`
    );
};

export const getResultDetailReq = (
  modelId: number,
  topic_uuid: string,
  level: number
) => {
  return api
    .getInstance()
    .get<ModelResultDetail>(
      `${DEEPTALK_API_HOST}/models/${modelId}/results/${topic_uuid}/?level=${level}`
    );
};

export const projectModelsCreateReq = (
  projectId: number,
  data: ProjectModelCreatePayload
) =>
  api
    .getInstance()
    .post<ProjectModelCreateRes>(
      `${DEEPTALK_API_HOST}/projects/${projectId}/models/`,
      data
    );

export const templateAnalysisBatchConfirmReq = (
  projectId: number,
  analysisId: number,
  batchId: number
) =>
  api.getInstance().post(`${API_DISTRIBUTOR}/deepers/test`, {
    analysis_type: "analysis.training.initiating",
    project_id: projectId,
    ml_model_id: analysisId,
    batch_id: batchId,
  });

export const templateAnalysisBatchCreateReq = (
  analysisId: number,
  template_name: string
) =>
  api
    .getInstance()
    .post<AnalysisBatch>(
      `${DEEPTALK_API_HOST}/template_analysis/${analysisId}/batches/`,
      { template_name }
    );

export const modelGet = (modelId: number) =>
  api
    .getInstance()
    .get<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${modelId}/`);

export const modelGetBatches = (modelId: number) =>
  api
    .getInstance()
    .get<AnalysisBatch[]>(`${DEEPTALK_API_HOST}/models/${modelId}/batches/`);

export const modelGetNTopicSegments = (modelId: number) =>
  api
    .getInstance()
    .get<{ count: number }>(
      `${DEEPTALK_API_HOST}/models/${modelId}/topic_segments_count/`
    );

export const modelGetSunburstData = (modelId: number, min_topic_size: number) =>
  api
    .getInstance()
    .get<any>(
      `${DEEPTALK_API_HOST}/models/${modelId}/sunburst/?min_size=${min_topic_size}`
    );

export const modelSetFileReq = (modelId: number, fileId: ProjectFile["id"]) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${modelId}/`, {
      uploaded_file: fileId,
    });

export const modelSetColumnMappingReq = (
  modelId: number,
  data: ModelTypeAndColumnMapping["column_mapping"]
) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${modelId}/`, {
      column_mapping: data,
      status: ModelStatus.Validating,
    } as Partial<ProjectTextModel>);

export const modelSetValueMappingReq = (
  modelId: number,
  data: ModelValueMapping
) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${modelId}/`, {
      value_mapping: data,
    } as Partial<ProjectTextModel>);

export const modelSetInitializedReq = (modelId: number) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${modelId}/`, {
      status: ModelStatus.Initialized,
    } as Partial<ProjectTextModel>);

export const modelSetConfirmationRequiredReq = (
  modelId: number,
  batchId: number
) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(
      `${DEEPTALK_API_HOST}/models/${modelId}/batches/${batchId}/`,
      {
        status: ModelStatus.ConfirmationRequired,
      } as Partial<ProjectTextModel>
    );

export const modelSetCategoriesReq = (
  model: ProjectTextModel,
  data: SaveCategoriesPayload
) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${model.id}/`, {
      status: ModelStatus.CategoriesAdded,
      metadata: {
        ...model.metadata,
        categories: data,
      },
    } as Partial<ProjectTextModel>);

export const modelSetUserExamplesForCategoryReq = (
  model: ProjectTextModel,
  categoryName: string,
  data: SaveCategoryUserExamplesPayload
) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${model.id}/`, {
      metadata: {
        ...model.metadata,
        user_examples: {
          ...(model.metadata?.user_examples || {}),
          [categoryName]: data,
        },
      },
    } as Partial<ProjectTextModel>);

export const modelSetUserExamplesAddedReq = (modelId: number) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${modelId}/`, {
      status: ModelStatus.UserExamplesAdded,
    } as Partial<ProjectTextModel>);

export const modelSetAnnotatedExamplesForCategoryReq = (
  model: ProjectTextModel,
  categoryName: string,
  data: SaveCategoryUserExamplesPayload
) =>
  api
    .getInstance()
    .patch<ProjectTextModel>(`${DEEPTALK_API_HOST}/models/${model.id}/`, {
      metadata: {
        ...model.metadata,
        annotated_examples: {
          ...(model.metadata?.annotated_examples || {}),
          [categoryName]: data,
        },
      },
    } as Partial<ProjectTextModel>);

export const modelDeleteCategoryMembershipReq = (
  segmentId: string,
  categoryUuid: string,
  membershipId: number | string
) => {
  const url = `${DEEPTALK_API_HOST}/category_membership/${membershipId}/`;
  return api.getInstance().delete(url);
};

export const modelAddCategoryMembershipReq = (
  antId: string,
  categoryUuid: string
) => {
  const url = `${DEEPTALK_API_HOST}/category_membership/`;
  return api
    .getInstance()
    .post(url, { analysis_unit_id: antId, category_id: categoryUuid });
};

export const updateTopicSegmentSentimentReq = (
  segmentId: string,
  sentiment: string
) => {
  const s = sentiment.toLowerCase();
  const sentValue = s === "positive" ? 0.9 : s === "negative" ? 0.1 : 0.5;
  const url = `${DEEPTALK_API_HOST}/topic_segment/${segmentId}/`;
  const res = api.getInstance().patch(url, { predicted_sentiment: sentValue });
  return sentValue;
};

export const modelGetCategorySentimentReq = (
  modelId: number,
  qParams: string
) => {
  const url =
    `${DEEPTALK_API_HOST}/models/${modelId}/category_sentiments/` + qParams;
  return api.getInstance().get<CategorySentimentResponseItem[]>(url);
};

export const modelGetTopicSentimentReq = (modelId: number, qParams: string) => {
  const url =
    `${DEEPTALK_API_HOST}/models/${modelId}/topic_sentiments/` + qParams;
  return api.getInstance().get<CategorySentimentResponseItem[]>(url);
};

export const getCategorySentiments = async (
  model_id: number,
  qParams: string
) => {
  const res = await modelGetCategorySentimentReq(model_id, qParams);
  const responseData = res.data;
  return formatCategorySentimentResponseData(responseData);
};

export const getTopicSentiments = async (model_id: number, qParams: string) => {
  const res = await modelGetTopicSentimentReq(model_id, qParams);
  const responseData = res.data;
  return formatCategorySentimentResponseData(responseData);
};

export const modelGetCategoryTimeEvolutionReq = (
  modelId: number,
  qParams: string,
  timeframe?: string,
  includeEmptyDocuments?: boolean
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/categories_evolution/`;
  url = url + qParams + `&timeframe=${timeframe ?? "month"}`;
  if (includeEmptyDocuments === true) {
    url = url + `&empty_documents=include`;
  } else {
    url = url + `&empty_documents=exclude`;
  }
  return api.getInstance().get<{
    time_evolution_data: CategoryTimeEvolutionResponseItem[];
    ant_count: number;
  }>(url);
};

export const modelGetTopicTimeEvolutionReq = (
  modelId: number,
  qParams: string,
  timeframe?: string,
  includeEmptyDocuments?: boolean
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/topic_evolution/`;
  url = url + qParams + `&timeframe=${timeframe ?? "month"}`;
  if (includeEmptyDocuments === true) {
    url = url + `&empty_documents=include`;
  } else {
    url = url + `&empty_documents=exclude`;
  }
  return api.getInstance().get<{
    time_evolution_data: CategoryTimeEvolutionResponseItem[];
    ant_count: number;
  }>(url);
};

export const getCategoryTimeEvolution = async (
  model_id: number,
  qParams: string,
  timeframe?: string,
  includeEmptyDocuments?: boolean
) => {
  const res = await modelGetCategoryTimeEvolutionReq(
    model_id,
    qParams,
    timeframe,
    includeEmptyDocuments
  );
  const responseData = res.data.time_evolution_data;
  return {
    time_evoution_data: formatCategoryTimeEvolutionResponseData(responseData),
    ant_count: res.data.ant_count,
  };
};

export const getTopicTimeEvolution = async (
  model_id: number,
  qParams: string,
  timeframe?: string,
  includeEmptyDocuments?: boolean
) => {
  const res = await modelGetTopicTimeEvolutionReq(
    model_id,
    qParams,
    timeframe,
    includeEmptyDocuments
  );
  const responseData = res.data.time_evolution_data;
  return {
    time_evoution_data: formatCategoryTimeEvolutionResponseData(responseData),
    ant_count: res.data.ant_count,
  };
};

export const getNumericalMetadataDistributionReq = (
  modelId: number,
  qParams: string,
  metric: string
) => {
  const url =
    `${DEEPTALK_API_HOST}/models/${modelId}/numerical_metadata_distribution_by_ant/?column_name=${metric}` +
    qParams.replace("?", "&");
  return api.getInstance().get<DistributionResponseItem[]>(url);
};

export const getIntegerNumericalMetadataDistribution = async (
  model_id: number,
  qParams: string,
  metric: string
) => {
  const res = await getNumericalMetadataDistributionReq(
    model_id,
    qParams,
    metric
  );
  const responseData_ = res.data;
  const responseData = responseData_.filter((x) => x.metadata_value !== null);
  responseData.sort((a, b) => {
    return a.metadata_value - b.metadata_value;
  });
  return responseData.map((item) => {
    return {
      value: item.metadata_value,
      count: item.ant_count,
      identifier: item.metadata_value.toString().split(".")[0],
    };
  });
};

export const modelGetMetricReq = (
  modelId: number,
  qParams: string,
  metric: string
) => {
  const url =
    `${DEEPTALK_API_HOST}/models/${modelId}/ants_count_by_score/?column_class=${metric}` +
    qParams.replace("?", "&");
  return api.getInstance().get<MetricResponseItem>(url);
};

export const getMetrics = async (
  model_id: number,
  qParams: string,
  metric: string,
  intl: IntlShape,
  maxCSAT?: number,
  maxCES?: number,
) => {
  const res = await modelGetMetricReq(model_id, qParams, metric);
  const responseData = res.data;
  return {
    formatted: formatMetricsResponseData(responseData, metric, intl, maxCSAT, maxCES),
    original: responseData,
  };
};

export const modelGetCSATMetricReq = (modelId: number, qParams: string) => {
  const url =
    `${DEEPTALK_API_HOST}/models/${modelId}/ants_count_by_score/?column_class=csat` +
    qParams.replace("?", "&");
  return api.getInstance().get<MetricResponseItem>(url);
};

// export const getCSATMetrics = async (model_id: number, qParams: string) => {
//   const res = await modelGetCSATMetricReq(model_id, qParams);
//   const responseData = res.data;
//   return formatMetricsResponseData(responseData, "csat");
// };

export const modelGetSentimentsByMetadataReq = (
  modelId: number,
  qParams: string,
  selectedOption: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/sentiments_by_metadata/`;
  url = url + qParams + `column_name=${encodeURIComponent(selectedOption)}`;
  url = url
    .replace("&&", "&")
    .replace("topic_uuid", "topic")
    .replace("??", "?");
  return api.getInstance().get<SentimentsByMetadataResponseItem[]>(url);
};

export const getSentimentsByMetadata = async (
  model_id: number,
  qParams: string,
  selectedOption: string
) => {
  const res = await modelGetSentimentsByMetadataReq(
    model_id,
    qParams,
    selectedOption
  );
  const responseData = res.data;
  return formatSentimentsByMetadataResponseData(responseData);
};

export const modelGetAntCountByMetadataReq = (
  modelId: number,
  qParams: string,
  selectedOption: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/metadata_frequency/`;
  url = url + qParams + `column_name=${encodeURIComponent(selectedOption)}`;
  url = url
    .replace("&&", "&")
    .replace("topic_uuid", "topic")
    .replace("??", "?");
  return api.getInstance().get<MetadataFrequencyResponseItem[]>(url);
};

export const getAntCountByMetadata = async (
  modelId: number,
  qParams: string,
  selectedOption: string
) => {
  const res = await modelGetAntCountByMetadataReq(
    modelId,
    qParams,
    selectedOption
  );
  const responseData = res.data;
  return formatSentimentFrequencyData(responseData);
};

export const modelGetMetricTimeEvolutionReq = (
  modelId: number,
  selectedOption: string,
  metric: string,
  qParams: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/metric_evolution/?${qParams}column_class=${metric}&timeframe=${selectedOption}`;
  url = url
    .replace("&&", "&")
    .replace("??", "?")
    .replace("topic_uuid", "topic");
  return api.getInstance().get<MetricTimeEvolutionResponseItem[]>(url);
};

export const getMetricTimeEvolution = async (
  model_id: number,
  selectedOption: string,
  metric: string,
  qParams: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  const res = await modelGetMetricTimeEvolutionReq(
    model_id,
    selectedOption,
    metric,
    qParams
  );
  const responseData = res.data;
  const filteredResponseData = responseData.filter((e) => e.date !== null);
  return formatMetricTimeEvolutionResponseData(
    filteredResponseData,
    metric,
    maxCSAT,
    maxCES
  );
};

export const modelGetMetricPerCategoryReq = (
  modelId: number,
  qParams: string,
  metric: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/metric_by_categories/?${qParams}column_class=${metric}`;
  url = url.replace("&&", "&").replace("??", "?");
  return api.getInstance().get<MetricByCategoryResponseItem>(url);
};

export const modelGetMetricPerTopicReq = (
  modelId: number,
  qParams: string,
  metric: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/metric_by_topics/?${qParams}column_class=${metric}`;
  url = url.replace("&&", "&").replace("??", "?");
  return api.getInstance().get<MetricByCategoryResponseItem>(url);
};
export const getMetricPerCategory = async (
  model_id: number,
  qParams: string,
  metric: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  const res = await modelGetMetricPerCategoryReq(model_id, qParams, metric);
  const responseData = res.data;
  return formatMetricPerCategoryResponseData(
    responseData,
    metric,
    maxCSAT,
    maxCES
  );
};

export const getMetricPerTopic = async (
  model_id: number,
  qParams: string,
  metric: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  const res = await modelGetMetricPerTopicReq(model_id, qParams, metric);
  const responseData = res.data;
  return formatMetricPerCategoryResponseData(
    responseData,
    metric,
    maxCSAT,
    maxCES
  );
};
export const modelGetMetricPerMetadataReq = (
  modelId: number,
  qParams: string,
  metric: string,
  selectedOption: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/nps_by_metadata/?${qParams}column_name=${selectedOption}&column_class=${metric}`;
  url = url.replace("&&", "&").replace("??", "?");
  return api.getInstance().get<MetricByMetadataResponse>(url);
};

export const getMetricPerMetadata = async (
  model_id: number,
  qParams: string,
  metric: string,
  selectedOption: string,
  maxCSAT?: number,
  maxCES?: number
) => {
  const res = await modelGetMetricPerMetadataReq(
    model_id,
    qParams,
    metric,
    selectedOption
  );
  const responseData = res.data;
  let fmtResponseData: any = {};
  Object.keys(responseData).map((key) => {
    fmtResponseData[key] = { value_counts: responseData[key] };
  });
  return formatMetricPerCategoryResponseData(
    fmtResponseData,
    metric,
    maxCSAT,
    maxCES
  );
};

export const modelGetMetricTimeEvolutionPerMetadataReq = (
  modelId: number,
  qParams: string,
  metric: string,
  selectedOption: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/nps_evolution_by_metadata/?${qParams}column_name=${selectedOption}&column_class=${metric}`;
  url = url.replace("&&", "&").replace("??", "?");
  return api.getInstance().get<MetricTimeDataByMetadataResponse>(url);
};

export const getMetricTimeEvolutionPerMetadata = async (
  model_id: number,
  qParams: string,
  metric: string,
  selectedOption: string,
  maxCSAT?: number
) => {
  const res = await modelGetMetricTimeEvolutionPerMetadataReq(
    model_id,
    qParams,
    metric,
    selectedOption
  );
  const responseData = res.data;
  return formatMetricTimeEvolutionPerMetadataResponseData(
    responseData,
    metric,
    maxCSAT
  );
};

export const modelGetSentimentDistributionReq = (
  modelId: number,
  qParams: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/sentiments/`;
  url = url + qParams;
  return api.getInstance().get<SentimentDistributionResponseItem>(url);
};

export const getSentimentDistribution = async (
  model_id: number,
  qParams: string
) => {
  const res = await modelGetSentimentDistributionReq(model_id, qParams);
  const responseData = res.data;
  return formatSentimentDistributionResponseData(responseData);
};

export const getCategoryByMetadataReq = (
  modelId: number,
  category: number,
  mdKey: string,
  qParams: string,
  nMin?: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/category_by_metadata/?category=${category}&md_key=${mdKey}`;
  if (qParams !== "?") {
    url = url + qParams.replace("?", "&");
  }
  if (nMin) {
    url = url + `&n_min=${nMin}`;
  }
  return api.getInstance().get(url);
};

export const getCategoryByMetadata = async (
  model_id: number,
  category: number,
  mdKey: string,
  qParams: string,
  nMin?: string
) => {
  const res = await getCategoryByMetadataReq(
    model_id,
    category,
    mdKey,
    qParams,
    nMin
  );
  const responseData = res.data;
  return formatCategoryByMetadataResponseData(responseData);
};

export const modelGetDocumentCountTimeEvolutionReq = (
  modelId: number,
  qParams: string,
  timeframe?: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/analysis_units_by_time/`;
  url = url + qParams + `&timeframe=${timeframe ?? "month"}`;
  url = url.replace("&&", "&").replace("??", "?").replace("?&", "?");
  return api.getInstance().get<CountOverTimeResponseItem[]>(url);
};

export const getDocumentCountTimeEvolution = async (
  model_id: number,
  qParams: string,
  timeframe?: string
) => {
  const res = await modelGetDocumentCountTimeEvolutionReq(
    model_id,
    qParams,
    timeframe
  );
  const responseData = res.data;
  const fmtData = responseData.map((item) => {
    return {
      ...item,
      date: offSetDate(item.date),
    };
  });
  return fmtData;
};

export const modelGetInteractionCountTimeEvolutionReq = (
  modelId: number,
  qParams: string,
  timeframe?: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/topic_segments_by_time/`;
  url = url + qParams + `&timeframe=${timeframe ?? "month"}`;
  url = url.replace("&&", "&").replace("??", "?").replace("?&", "?");
  return api.getInstance().get<CountOverTimeResponseItem[]>(url);
};

export const modelGetActiveUserCountTimeEvolutionReq = (
  modelId: number,
  qParams: string,
  timeframe?: string
) => {
  var url = `${DEEPTALK_API_HOST}/models/${modelId}/active_users_by_time/`;
  url = url + qParams + `&timeframe=${timeframe ?? "month"}`;
  url = url.replace("&&", "&").replace("??", "?").replace("?&", "?");
  return api.getInstance().get<UserCountOverTimeResponseItem[]>(url);
};

export const getInteractionCountTimeEvolution = async (
  model_id: number,
  qParams: string,
  timeframe?: string
) => {
  const res = await modelGetInteractionCountTimeEvolutionReq(
    model_id,
    qParams,
    timeframe
  );
  const responseData = res.data;
  const fmtData = responseData.map((item) => {
    return {
      ...item,
      date: offSetDate(item.date),
    };
  });
  return fmtData;
};

export const getActiveUserTimeEvolution = async (
  model_id: number,
  qParams: string,
  timeframe?: string
) => {
  const res = await modelGetActiveUserCountTimeEvolutionReq(
    model_id,
    qParams,
    timeframe
  );
  const responseData = res.data;
  const fmtData = responseData.map((item) => {
    return {
      ...item,
      date: offSetDate(item.date),
    };
  });
  return fmtData;
};
